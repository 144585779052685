/* eslint-env jest */
import Viewcell from '@/models/Viewcell';
import mutations from './mutations';

jest.mock('@vivotek/lib-medama', () => ({
  PLUGINFREE: {
    SUPPORT_VIDEO_CODEC: [],
  },
}));

beforeAll(() => {
  jest.spyOn(Viewcell.prototype, 'queryRecordingData').mockImplementation(() => jest.fn().mockResolvedValue({}));
});

afterAll(() => {
  jest.restoreAllMocks();
});

describe('mutations', () => {
  describe('updateTime', () => {
    it('should set options and playTime with assigned value', () => {
      const state = {
        view: [{
          options: {
            begin: 0,
            end: 0,
          },
          playTime: {
            timestamp: 0,
            tzoffs: 0,
          }
        }]
      };

      mutations.updateTime(state, {
        target: state.view,
        begin: 10,
        end: 20,
        timestamp: 30,
        tzoffs: 40
      });

      expect(state.view[0].options.begin).toBe(10);
      expect(state.view[0].options.end).toBe(20);
      expect(state.view[0].playTime.timestamp).toBe(30);
      expect(state.view[0].playTime.tzoffs).toBe(40);
    });

    it('should set playTime.timestamp with begin(localTime) timestamp if arguments do not include timestamp', () => {
      const state = {
        view: [{
          options: {
            begin: 0,
            end: 0,
          },
          playTime: {
            timestamp: 0,
            tzoffs: 0,
          }
        }]
      };
      const localTimeBegin = {
        projection: jest.fn().mockReturnValue({
          timestamp: 6666
        })
      };
      mutations.updateTime(state, {
        target: state.view,
        begin: localTimeBegin,
        end: 20,
        tzoffs: 40
      });

      expect(state.view[0].options.end).toBe(20);
      expect(state.view[0].playTime.timestamp).toBe(6666);
      expect(state.view[0].playTime.tzoffs).toBe(40);
    });
  });
  describe('addSelectedEncoder', () => {
    it('addSelectedEncoder should set first nonempty viewcell time', () => {
      const encoders = {
        0: {},
      };
      const state = {
        layout: '1x1',
        view: [{
          channel: 2,
          options: {
            begin: { timestamp: 10000, tzoffs: 0 },
            end: { timestamp: 20000, tzoffs: 0 }
          }
        }],
      };
      mutations.addSelectedEncoder(state, {
        channel: 0,
        encoders,
        timezone: -28800,
      });
      expect(state.view[1].options.begin).toEqual({ timestamp: 10000, tzoffs: 0 });
      expect(state.view[1].options.end).toEqual({ timestamp: 20000, tzoffs: 0 });
      expect(state.view[1].playTime.timestamp).toEqual(10000);
      expect(state.view[1].playTime.tzoffs).toEqual(-28800);
    });
    it('addSelectedEncoder should set encoder data and set active encoderId to state', () => {
      const encoders = {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
        10: {},
      };
      const state = {
        layout: '1x1',
        view: [{
          channel: -1,
        }],
      };
      mutations.addSelectedEncoder(state, {
        channel: 0,
        encoders,
      });
      expect(state.view[0].channel).toEqual(0);
      expect(state.activeEncoderId).toEqual(0);
      expect(state.layout).toEqual('1x1');
      mutations.addSelectedEncoder(state, {
        channel: 1,
        encoders,
      });
      expect(state.view[1].channel).toEqual(1);
      expect(state.activeEncoderId).toEqual(1);
      expect(state.layout).toEqual('2x2');
      mutations.addSelectedEncoder(state, {
        channel: 3,
        encoders,
      });
      expect(state.view[2].channel).toEqual(3);
      expect(state.activeEncoderId).toEqual(3);
      expect(state.layout).toEqual('2x2');
      mutations.addSelectedEncoder(state, {
        channel: 10,
        encoders,
      });
      expect(state.view[3].channel).toEqual(10);
      expect(state.activeEncoderId).toEqual(10);
      expect(state.layout).toEqual('2x2');
      mutations.addSelectedEncoder(state, {
        channel: 4,
        encoders,
      });
      expect(state.view[4].channel).toEqual(4);
      expect(state.activeEncoderId).toEqual(4);
      expect(state.layout).toEqual('3x3');
    });
  });

  it('removeSelectedEncoder should remove encoder data from state', () => {
    const encoders = {
      0: {},
      1: {},
      2: {},
      3: {},
      4: {},
      10: {},
    };

    const state = {
      view: [{
        channel: 1,
      }, {
        channel: 2,
      }, {
        channel: 3,
      }, {
        channel: 4,
      }],
      layout: '2x2',
      activeEncoderId: 1,
    };

    mutations.removeSelectedEncoder(state, {
      channel: 1, encoders
    });
    expect(state.view[3].channel).toEqual(4);
    expect(state.activeEncoderId).toEqual(-1);

    mutations.removeSelectedEncoder(state, {
      channel: 2, encoders
    });
    expect(state.view[2].channel).toEqual(3);

    mutations.removeSelectedEncoder(state, {
      channel: 4, encoders
    });
    expect(state.view.length).toEqual(4);
    expect(state.view[0].channel).toEqual(-1);
    expect(state.layout).toEqual('2x2');

    mutations.removeSelectedEncoder(state, {
      channel: 3, encoders
    });
    expect(state.view[0].channel).toEqual(-1);
    expect(state.layout).toEqual('2x2');
  });

  it('changeLayout should remove viewcells when new layout count is less than current layout count', () => {
    const state = {
      view: [{
        channel: 1,
      }, {
        channel: -1,
      }, {
        channel: -1,
      }, {
        channel: -1,
      }],
      layout: '2x2',
    };

    mutations.changeLayout(state, '1x1');
    expect(state.layout).toEqual('1x1');
    expect(state.view.length).toEqual(1);
  });

  it('changeLayout should add empty viewcells when new layout count is greater than current layout count', () => {
    const state = {
      view: [{
        channel: 1,
      }],
      layout: '1x1',
    };

    mutations.changeLayout(state, '2x2');
    expect(state.layout).toEqual('2x2');
    expect(state.view.length).toEqual(4);
  });
});
